.text-editor {
    background: #FCFCFC;
    box-shadow: inset 0px 2px 4px rgba(5, 38, 38, 0.05);
    border-radius: 5px;
    margin: 40px 0px 20px 0px;
    padding: 12px;

    &__panel {
        margin-top: 16px;

        &::after {
            content: "";
            clear: both;
            display: table;
        }
    }

    &__input {
        outline: none;
        overflow: hidden;
        overflow-y: auto;
        max-height: 84px;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track-piece {
            &:start {
                background: transparent;
                margin-top: 5px;
            }

            &:end {
                background: transparent;
                margin-bottom: 5px;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: #ADB5B3;
        }

        & font {
            // color: #4F5252 !important;
        }
    }

    &__input:empty::before {
        content: attr(placeholder);
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #9FA3A3;
    }

    &__input:empty:focus::before {
        content: "";
    }

    .length-counter {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        text-align: right;
        color: #9FA3A3;
    }

    .panel-btn {
        float: left;
        background: transparent;
        border: none;
        cursor: pointer;
        float: left;
        padding: 4px;
        margin: 0 12px 0 0;

     
    }

    .icon-font-bold_active {
        border: 1px solid #069685;
        border-radius: 5px;
    }

    .icon-font-cursive_active {
        border: 1px solid #069685;
        border-radius: 5px;
    }

    .icon-font-underline_active {
        border: 1px solid #069685;
        border-radius: 5px;
    }

    .font-size-container {
        display: flex;
        align-items: center;
        float: left;
        height: 32px;
    }

    .font-size {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
    }

    .range-slider {
        width: 59px;
    }

    .font-size-select {
        background: #FCFCFC;
        box-shadow: inset 0px 2px 4px rgba(5, 38, 38, 0.05);
        border-radius: 5px;
        width: 47px;
        height: 32px;
        font-weight: 500;
        font-size: 16px;
        color: #535757;
        font-family: 'Roboto', sans-serif;
        font-style: none;

        .dropdown-header {
            position: relative;
            height: 100%;
            width: 100%;

            .icon-font-size {
                &:before {
                    position: absolute;
                    top: calc(50% - 12px);
                    left: 12px;
                }
            }
        }

        .font-size-option {
            display: inline-block;
            width: 100%;
            height: 100%;
            line-height: 32px;
            text-align: center;
        }
    }

    .font-color-select {
        background: #FCFCFC;
        box-shadow: inset 0px 2px 4px rgba(5, 38, 38, 0.05);
        border-radius: 5px;
        width: 47px;
        height: 32px;
        float: right;

        .dropdown-header {
            position: relative;
            height: 100%;
            width: 100%;

            .icon-color {
                position: absolute;
                top: calc(50% - 10px);
                left: 4px;
            }

            .icon-font-color {
                &:before {
                    position: absolute;
                    top: calc(50% - 12px);
                    left: 4px;
                }
            }
        }
    }

    .font-fill-select {
        background: #FCFCFC;
        box-shadow: inset 0px 2px 4px rgba(5, 38, 38, 0.05);
        border-radius: 5px;
        width: 47px;
        height: 32px;
        float: right;
        margin: 0 0 0 12px;

        .icon-color {
            &:before {
                // font-size: 24px;
            }
        }

        .dropdown-header {
            position: relative;
            height: 100%;
            width: 100%;

            .icon-color {
                position: absolute;
                top: calc(50% - 10px);
                left: 4px;
            }

            .icon-fill-color {
                &:before {
                    position: absolute;
                    top: calc(50% - 12px);
                    left: 4px;
                }
            }
        }
    }
}