.table-view {
	table-layout: fixed;
	margin: 0;

	thead {
		td {
			&:first-child {
				border: none;
			}
		}

		.table-weeknumber {
			background-color: #328B76;
			color: #FFFFFF;
			position: relative;
			
			&.period-is-month {
				font-size: 20px;
				line-height: 23px;
			}

			&.period-is-half-year {
				font-size: 18px;
			}

			&.day-of-month--current-week {
				background-color: #00AD88;
			}

			&.another-month-week-left-border::after {
				content: "";
				height: 32px;
				width: 1px;
				background-color: #000000;
				position: absolute;
				left: -1px;
				bottom: 19px;
			}

			&.another-month-week-right-border::after {
				content: "";
				height: 32px;
				width: 1px;
				background-color: #000000;
				position: absolute;
				right: -1px;
				bottom: 19px;
			}
		}

		.month {
			text-align: center;
			text-transform: uppercase;

			&.plus-first-week {
				padding-left: calc(100% / 19);
			}

			&.plus-last-week {
				padding-right: calc(100% / 19);
			}
		}
	}

	.weekdays {
		color: #328B76;
	}

	.weekend {
		color: #E01010;
	}

	.holiday {
		color: #E01010 !important;
	}

	.day-of-month {

		height: calc((100vh - 83px)/7);
		-ms-overflow-style: none;
		scrollbar-width: none;
		scroll-behavior: smooth;

		.calendar-event-title {
			overflow: hidden;
			color: #000000;

			font * {
				line-height: 100%;
			}

		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.day-of-month--current-day {
		
		-webkit-box-shadow: inset 0px 0px 0px 3px #89CCC4;
		box-shadow: inset 0px 0px 0px 3px #89CCC4;
		animation: 2s rotate linear infinite;
	
	}
	  
	  @keyframes rotate {
		0% {
			box-shadow: inset 0px 0px 0px 3px #89CCC4;
		}
		25% {
			box-shadow: inset 0px 0px 0px 3px #2ddde0;
		}
		50% {
			box-shadow: inset 0px 0px 0px 3px #117ea3;
		}
		75% {
			box-shadow: inset 0px 0px 0px 3px #2ddde0;
		}
		100% {
			box-shadow: inset 0px 0px 0px 3px #89CCC4;
		}
	  }
	  
	

	&.period-is-month {
		.day-of-month {
			font-size: 24px;
		}
	}

	&.period-is-half-year {
		.day-of-week-month {
			font-size: 14px;
		}
	}

	&.period-is-year {
		.day-of-week-month {
			font-size: 14px;
		}
	}

	.period-is-month {
		width: auto;
	}

	.thematic-cell {
		border: 0px;
	}

}

.Skeuomorphism .calendar-event {
	// box-shadow: 0px 0px 0px 0.42px rgba(0, 0, 0, 1);
	// border: 0.42px solid black!important;
	border-radius: 3px;
	box-shadow: inset 0px 0.4px 0.4px 0.1px #FDFFFE;
	// border-image-source: linear-gradient(310.09deg, rgba(255, 255, 255, 0) 67.38%, #FFFFFF 105.43%),
	// linear-gradient(54.12deg, rgba(255, 255, 255, 0) 79.14%, #FFFFFF 101.37%);
	
	
}


.Skeuomorphism .day-of-month {

	height: calc((100vh - 130px)/7);
}
.Skeuomorphism .period-is-week{

	height: calc((100vh - 150px)/2);
}
.thematic-selection-td {
	z-index: 4;
}

.thematic-date-selection {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.15);
	z-index: 3;
}

.table-view,
.table-view th,
.table-view td {
	border: 1px solid #4F5252;
	border-collapse: collapse;
}

.table-view .saturday,
.table-view .sunday {
	color: #328B76;
}

.date-picker {
	cursor: pointer;
	height: 42px;
	border-radius: 5px;
	box-shadow: 2px 2px 5px 2px #0000001A;
	border: 0;
	box-shadow: 2px 2px 2px 2px #19221F12;
	padding: 8px;
}
.Skeuomorphism .table-day {
	background-color: #EBEBEB;
}

.table-current-day {
	border-radius: 2px;
	border: 2px solid black;
}
.Skeuomorphism .table-current-day::before {
		content: "";
		position: absolute;
		top: -2px;
		left: -2px;
		right: -2px;
		bottom: -2px;
		border-radius: 3px;
		z-index: 1;
		border: 4px solid transparent;
		background:linear-gradient(310.09deg, black 100%, black 100%);
		-webkit-mask: 
			linear-gradient(#fff 0 0) content-box, 
			linear-gradient(#fff 0 0);
		-webkit-mask-composite: xor;
			mask-composite: exclude; 
}

// @keyframes cycle {
// 	0% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 0% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	5% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 10% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	10% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 20% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	15% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
// 		radial-gradient(77% 96.77% at 30% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	20% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
// 		radial-gradient(77% 96.77% at 40% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	25% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 50% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	30% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 60% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	35% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 70% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	40% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 80% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	45% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 90% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	50% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
// 		radial-gradient(77% 96.77% at 100% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	55% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
// 		radial-gradient(77% 96.77% at 110% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	60% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
// 		radial-gradient(77% 96.77% at 120% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	65% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
// 		radial-gradient(77% 96.77% at 130% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	70% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
// 		radial-gradient(77% 96.77% at 140% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	75% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
// 		radial-gradient(77% 96.77% at 150% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	80% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 160% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	85% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 170% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	90% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 180% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	95% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 190% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// 	100% {
// 		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
// 		radial-gradient(77% 96.77% at 0% 50%, #000000 0%, #020202 34.9%, #BDBDBD 60.83%, #666666 74.06%, #000000 90.88%);
// 	}
// }

@scope(.Skeuomorphism) {

	.month-number {
		background: linear-gradient(180deg, #CACCCB 50%, #B2B4B3 50%);
		border: 0.42px solid black !important;
		box-shadow: inset 0px 1px 1px 0.5px #FDFFFE;
		border-radius: 3px;
		cursor: default;
		height: 29px;
		display: flex;
		margin-left:44px;
		margin-right:2px;
		gap: 10px;
		justify-content: center;
		align-items: center;
		&--span {
			background: linear-gradient(0deg, #2C2B29 50%, #656563 50%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			color: transparent;
			font-family: Inter;
			font-size: 16px;
			font-weight: 700;
			line-height: 19.36px;
			letter-spacing: 0.01em;
			text-align: center;
		
		}
	
	}
	.month-and-week-number {
        background: linear-gradient(180deg, #cacccb 50%, #b2b4b3 0) !important;

		margin-left:2px;
		margin-right:2px;
		border: 0.42px solid #000 !important;
		box-shadow: inset 0 1px 1px 0.5px #fdfffe !important;
		border-radius: 3px;
		cursor: pointer;
		height: 29px;
		display: flex;
		gap: 16px;
		justify-content: center;
		align-items: center;
		&--span {
			background: linear-gradient(0deg, #2C2B29 50%, #656563 50%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			color: transparent;
			font-family: Inter;
			font-size: 16px;
			font-weight: 700;
			line-height: 19.36px;
			letter-spacing: 0.01em;
			text-align: center;
		
		}
		&:hover {
			filter:brightness(1.1)
		}
	}
}
.modern-view {
	thead {
		tr {
			&:last-child {
				td {
					color: #5DA299;
					background: #E4F2EF;
					margin: 0 1px 12px;
				}
			}
		}
	}

	td {
		&:first-child {
			-webkit-box-shadow: none;
			box-shadow: none;
			width: 65px;
			background: #FAFAFA;
		}
	}

	.day-of-month {
		width: 99px;
		height: 100px;
		-webkit-box-shadow: 0 2px 3px rgba(42, 92, 77, 0.05);
		box-shadow: 0 2px 3px rgba(42, 92, 77, 0.05);
	}

	.day-of-month--not-this-month {
		color: #CED6D6;
	}

	.day-of-month--past-day {
		-webkit-box-shadow: none;
		box-shadow: none;
		background: #FAFAFA;
		background: rgba(0, 0, 0, 0) url("../../../styles/images/cross.svg") no-repeat;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.day-of-month--past-week {
		-webkit-box-shadow: none;
		box-shadow: none;
		background: #FAFAFA;
	}

	.day-of-month--current-day {
		border: 1px solid #89CCC4;
	}
}

.alternate-view {
	td {
		&:first-child {
			-webkit-box-shadow: none;
			box-shadow: none;
			width: 65px;
			background: #FAFAFA;
		}
	}

	.alternate-weeknumber {
		background: linear-gradient(144.33deg, #05C8A5 -20.64%, #0C978F 90.26%);
		color: #FFFFFF;
	}

	.alternate {
		width: 245px;

		.day-icon {
			left: -84px;
			border-radius: 50%;
			width: 52px;
			height: 52px;
			display: inline-block;
			text-align: center;
			line-height: 52px;
			background-color: #FAFAFA;
			color: #0a2d24;
		}
	}

	.alternate-icon {
		width: 36px;
		height: 36px;
		border-radius: 2px;
		text-align: center;
		line-height: 36px;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 36px;
		flex: 0 0 36px;
		margin: 4px;
	}

	.alternate-events {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		margin-left: 40px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		width: 200px;
	}

	.alternate-current-day {
		background-color: #0AB791;
	}

	.alternate-past-day {
		.day-icon {
			color: #D8DAD9;
		}
	}
}

.period-is-month {
	height: 40px;
}

.period-is-half-year {
	height: 27px;
}

.period-is-half-year-day {
	width: 100px;
	height: calc((100vh - 2*27px - 68px - 2*32px)/14);
}
.Skeuomorphism .period-is-half-year-day {
	width: 100px;
	height: calc((100vh - 2*27px - 100px - 2*32px)/14);
}

.period-is-year {
	height: 19px;
	font-size: 14px;
	line-height: 14px;
}
.Skeuomorphism .period-is-year {
		height: 31px;
		font-size: 14px;
		line-height: 14px;
}
.period-is-quarter-year {
	height: 24px;
	font-size: 14px;
	line-height: 14px;
	
}
.period-is-quarter-year-weekday {
	max-width: 42px;
	width: 42px;
	
}

.period-is-quarter-year .weekdays {
	max-width: 42px;
	width: 42px;
	
}

.Skeuomorphism .shifted-to-top {
	left: 0;
	top: -4px;
	position: absolute;
	bottom: 0
}

.period-is-year-day {
	width: 72px;
	height: calc((100vh - 3*19px - 135px - 3*32px)/21);
	height: calc((100vh - 3*19px - 45px - 3*32px)/21);
	font-size: 14px;
	line-height: 14px;
}
.Skeuomorphism .period-is-year-day {
	width: 72px;
	height: calc((100vh - 3*19px - 148px - 3*32px)/21);
	font-size: 14px;
	line-height: 14px;
	
}
@scope(.Skeuomorphism) {
	.period-is-year .day-of-week-month {
		font-size: 11px !important;
		line-height: 11px; 
	
	}
	.period-is-half-year .day-of-week-month {
		font-size: 16px !important;
		line-height: 16px; 
	
	}

	.period-is-quarter-year .table-weeknumber {
		font-size: 16px !important;
		line-height: 16px; 
	
	}
} 

.period-is-quarter-year-day {
	width: 72px;
	height: calc((100vh - 3*19px - 40px )/7);
	font-size: 14px;
	line-height: 14px;

}
.Skeuomorphism .period-is-quarter-year-day {
	width: 72px;
	height: calc((100vh - 3*19px - 78px )/7);
	font-size: 14px;
	line-height: 14px;

}

.Skeuomorphism .gray {
	background-color: rgba(235, 235, 235, 1)	;
}

.month {
	height: 32px;
	font-size: 16px;
	line-height: 19px;
	text-align: left;

}
.Skeuomorphism .month {
	background: linear-gradient(180deg, #CACCCB 50%, #B2B4B3 50%);
	border: 0.42px solid black !important;
	box-shadow: inset 0px 1px 1px 0.5px #FDFFFE;
	// border-image-source: linear-gradient(310.09deg, rgba(255, 255, 255, 0) 67.38%, #FFFFFF 105.43%);
	border-radius: 3px;	
	cursor: pointer;
	&:hover {
		filter:brightness(1.1)
	}
}
.calendar-event-delete {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	position: relative;
}

.calenday-day-container__extra {
	z-index: 3;
	position: absolute;
	bottom: 1px;
	right: 1px;
	padding-left: 2px;
	padding-right: 2px;
	padding-top: 1px;
	padding-bottom: 1px;
	border-radius: 10px;
	background-color: #FFFFFF;
	font-size: 10px;
	font-weight: 500;
	color: #4F5252;
	min-width: 14px;
}

.stick-to-right {
	right: 0;
}

.stick-to-left {
	left: 0;
}

.stick-to-top {
	top: 0;
}

.stick-to-bottom {
	bottom: 0;
}

.calendar-day-container {
	margin: 1px;
	position: relative;
	-ms-overflow-style: none;
	scrollbar-width: none;
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		display: none;
	}

	.overlap {
		clip-path: inset(-100% 0 0 0);

		.calendar-event {
			color: #4F5252;
			border-radius: 2px;
			height: 50%;
			width: 49%;
			overflow: hidden;
			// position: absolute;
			z-index: 2;



			&.full-height {
				z-index: 1;
			}




			&.half {
				// &:hover {
				// 	transform: translateY(-30%);

				// }
			}

			&.full-width {
				// &:hover {
				// 	transform: translateY(-40%);

				// }
			}

			&.full {
				// &:hover {
				// 	transform: translateY(-70%);

				// }
			}
		}

		// &:hover {
		// 	z-index: 4;			
		// }
	}

	.second {
		right: 0;
	}

	.calendar-events-container {
		top: 0;
		left: 0;
		padding: 1px;
		gap: 1px;
		position: absolute;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		width: 100%;
		height: 100%;
		z-index: 2;

		.calendar-event {
			color: #4F5252;
			border-radius: 2px;
			height: 49%;
			width: 49%;
			overflow: hidden;
			z-index: 2;

			&.full-height {
				height: 99%;
			}

			&.full-width {
				width: 100%;
			}

			&.full {
				height: 99%;
				width: 100%;
			}

			.calendar-event-title {
				line-height: 100%;
				float: left;
				width: 100%;
				text-align: left;
				padding: 3px;
				overflow: hidden;

				i {
					float: unset;
				}
			}
		}
	}

	&.period-is-half-year-day {
		.calendar-event-title {
			line-height: 100%;
			color: #000000;

			font * {
				line-height: 100%;
			}
		}

		.calendar-event {
			height: 49%;

			&.full-height {
				height: 99%;
			}

			&.full-width {
				width: 100%;
			}

			&.full {
				height: 99%;
				width: 100%;
			}


		}
	}

	&.period-is-year-day {
		.calendar-event-title {
			line-height: 100%;
			color: #000000;
			font * {
				line-height: 100%;
			}
		}

		.calendar-event {
			height: 49%;

			&.full-height {
				height: 99%;
			}

			&.full-width {
				width: 100%;
			}

			&.full {
				height: 99%;
				width: 100%;
			}


		}
	}
}

.calendar-event-title>span {
	position: absolute;
	top: 1px;
}

.event-title-first-line {
	position: absolute;
	top: 1px;
}

.event-title-nextline {}

.calendar-day-container__number {
	left: 50%;
	top: 50%;
	position: absolute;
	transform: translateX(-50%) translateY(-50%);
	z-index: 3;
	cursor: pointer;
	font-weight: 600;
	text-shadow: -1px -.5px 1px white, 1px 1px 1px white, 1px -.5px 1px white, -1px 1px 1px white;
	
	
	
}

.Skeuomorphism .calendar-day-container__number {
	text-shadow: none;
	&:hover {
		filter:brightness(1.1)
	}
}

.Skeuomorphism  .calendar-day-container__number {
	width: 32px;
	height: 32px;
	display: flex;
	font-weight: 700;
	font-size: 24px;
	justify-content: center;
	align-items: center;
	border-image-source: linear-gradient(310.09deg, rgba(255, 255, 255, 0) 67.38%, #FFFFFF 105.43%),
	linear-gradient(54.12deg, rgba(255, 255, 255, 0) 79.14%, #FFFFFF 101.37%);
	-webkit-box-shadow: inset 0px 0.5px 0px 1px rgba(255,255,255,1);
	-moz-box-shadow: inset 0px 0.5px 0px 1px rgba(255,255,255,1);
	box-shadow: inset 0px 0.4px 0px 0.5px rgba(255,255,255,1);
	background: linear-gradient(180deg, #DDDFDE 49.99%, #C5C7C6 50%);
	border: 0.42px solid black !important;
	border-radius: 3px;
	
}

.Skeuomorphism .period-is-year-day .calendar-day-container__number {
	width: 16px;
	height: 16px;
	font-size: 11px;
}
.Skeuomorphism .period-is-quarter-year-day .calendar-day-container__number {
	width: 24px;
	height: 24px;
	font-size: 16px;
}
.Skeuomorphism .period-is-half-year-day .calendar-day-container__number {
	width: 24px;
	height: 24px;
	font-size: 16px;
}
.Skeuomorphism .period-is-half-year-day .calendar-day-container__number {
	width: 24px;
	height: 24px;
	font-size: 16px;
}



thead {
	&>tr {
		&>td {
			&.day-of-month--current-week {
				background: #89CCC4;
				color: #fff;

				&.alternate-weeknumber {
					background: #0AB791;
				}
			}

			&.day-of-week-month {
				&.day-of-month--current-week {
					&.blue {
						background: #5FACE4;
					}
				}

				&.blue {
					background: #4995CC;
					color: #fff;
				}
			}
		}
	}
}

th {
	&:first-child {
		-webkit-box-shadow: none;
		box-shadow: none;
		background: #FAFAFA;
	}
}

tbody {
	tr {
		background: #FFFFFF;
		border-radius: 2px;
		color: #000000;
	}
}


table {
	border: 0;
	text-align: center;
	margin-bottom: 28px;
	width: 100%;
}

.calendar-events-counter {
	text-align: center;
	font-size: 2em;
}

.copied-event {
	background: linear-gradient(-45deg, #d6d5e2, #19bfab, #0032ff) ;
	background-size: 400% 400% ;
	animation: gradient 5s ease infinite ;
}
@scope (.Skeuomorphism) {
	.copied-event {
		background-image: linear-gradient(-45deg, #d6d5e2, #19bfab, #0032ff) !important;
		background-size: 400% 400% !important;
		animation: gradient 5s ease infinite !important;
	}
	.cut-event {
		background-image: linear-gradient(-45deg, #020024, #e73c7e, #bf1943) !important;
		background-size: 400% 400% !important;
		animation: gradient 5s ease infinite !important;
	
	}
}



.cut-event {
	background: linear-gradient(-45deg, #020024, #e73c7e, #bf1943);
	background-size: 400% 400%;
	animation: gradient 5s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.overflow-hidden {
	overflow: hidden;
}


.Skeuomorphism .table-weeknumber {
	background: linear-gradient(180deg, #84AEA4 50%, #4D897D 50%);
	border: 1px solid black !important;
	font-weight: 800;
	height: 31px;
	// border-image-source: linear-gradient(310.09deg, rgba(255, 255, 255, 0) 67.38%, #FFFFFF 105.43%),
	// 	linear-gradient(54.12deg, rgba(255, 255, 255, 0) 79.14%, #FFFFFF 101.37%);
	border-radius: 3px;	
	box-shadow: inset 0px 1px 1px 0.5px #FDFFFE;
	cursor: pointer;
	&:hover {
		filter:brightness(1.1)
	}

}
.Skeuomorphism .plus-first-week {

}
.Skeuomorphism .another-month-week-right-border::after {
	background-color: white !important;
	bottom: 32px !important;
	border-top: 0;
	border-bottom: 0;
	border-left: 1px solid black;
	border-right: 1px solid black;
	width: 2px !important;
	right: -3px !important;
	
}
.Skeuomorphism .another-month-week-left-border::after {
	background-color: white !important;
	bottom: 32px !important;
	border-top: 0;
	border-bottom: 0;
	border-left: 1px solid black;
	border-right: 1px solid black;
	width: 2px !important;
	left:-3px !important;
}

.Skeuomorphism .weekdays {
	color: #8D8D8D;
	background-color: #EBEBEB;
	}

.Skeuomorphism .weekend {
	color: #8D8D8D
}

.Skeuomorphism .day-of-month--current-day {
		
	
	animation: none;
	box-shadow: none;
	padding: 0%;
	
	.calendar-day-container__number{
		background: linear-gradient(180deg, #616262 50%, #141515 50.01%);

		border-image-source: linear-gradient(310.09deg, rgba(255, 255, 255, 0) 67.38%, #FFFFFF 105.43%),
		linear-gradient(54.12deg, rgba(255, 255, 255, 0) 79.14%, #FFFFFF 101.37%);
		
		box-shadow: inset 0px 0.4px 0.4px 0.1px #FDFFFE;
		
		color: #FFFFFF;
	}


}
.Skeuomorphism {
	.calendar-day-container {
		margin: 0;
		padding: 0;
	}
	.period-is-year .calendar-day-container {

	}
	.table-view,
	.table-view th,
	.table-view td {
		border: none;
		border-collapse: unset;
	}	 
}

@scope (.Skeuomorphism) {
    .day-of-month--current-week {
		background: linear-gradient(180deg, #616262 50%, #141515 50.01%) !important;

	}
}
