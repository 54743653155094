.settings-container {
    width: 340px;
    background: #FFFFFF;
    box-shadow: 0 9px 10px rgba(42, 92, 77, 0.05), 0px 2px 3px rgba(42, 92, 77, 0.05), 0px 10px 20px 10px rgba(17, 59, 68, 0.05);
    display: block;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 9;
    border: 1.2px solid #4F5252;

    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}
.Skeuomorphism .link{
    font-family: 'Inter';
}
.link {
    text-decoration: none;
    margin-bottom: 20px;
    color: #069685;
    font-family: 'Roboto';
    font-weight: 500;
}
.settings-header {
    padding: 8px 10px 8px 28px;
    border-bottom: 1px solid #000000;

    &::after {
        content: " ";
        clear: both;
        display: table;
    }

    h1 {
        float: left;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #535757;
        margin: 0;
    }

    .settings-exit {
        border: none;
        background: none;
        cursor: pointer;
        float: right;
    }
}

.settings-body {
    padding: 28px 28px 22px 28px;
}

.settings-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #535757;
    margin: 0;
}

.settings-header-icon {
    margin-left: 140px;
    font-size: 36px;

    i {
        font-size: 36px;
        color: #069685;
    }
}

.settings-checkbox {
    label {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        display: grid;
        grid-template-columns: 1em auto;
        gap: 0.5em;
    }
}

.settings-checkbox,
.settings-radio {
    margin-bottom: 10px;
}

.settings-radio {
    label {
        display: grid;
        grid-template-columns: 1em auto;
        gap: 0.5em;
        box-sizing: border-box;
        color: #252525;
        cursor: pointer;
    }
}

.settings-confirm-button {
    button {
        background: linear-gradient(180deg, #069685 0%, #069685 100%);
        border-radius: 5px;
        height: 42px;
        width: 100%;
        padding: 11px 11px 11px 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        cursor: pointer;
    }
}

input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    border: 2px solid #069685;
    border-radius: 50%;
    height: 1.15em;
    width: 1.15em;
    transform: translateY(0.275em);
    display: grid;
    place-content: center;
    cursor: pointer;
}

input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #069685;
}

input[type="radio"]:checked {
    &::before {
        transform: scale(1);
    }
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: #069685;

    border: 0.15em solid #FDFDFD;
    border-radius: 1em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    box-shadow: 0px 2px 5px 0px #00000066 inset;
    width: 24px;
    height: 24px;

}

input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #069685;
    border: 0.15em solid #FDFDFD;
    border-radius: 1em;

}

input[type="checkbox"]:checked {
    &::before {
        transform: scale(1);
    }
}

.icon-bin-settings::before {
    background-image: url('data:image/svg+xml,<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.1667 6.96H19.8333V4.76C19.8333 3.78925 19.0859 3 18.1667 3H9.83333C8.91406 3 8.16667 3.78925 8.16667 4.76V6.96H4.83333C4.3724 6.96 4 7.35325 4 7.84V8.72C4 8.841 4.09375 8.94 4.20833 8.94H5.78125L6.42448 23.3225C6.46615 24.2603 7.20052 25 8.08854 25H19.9115C20.8021 25 21.5339 24.263 21.5755 23.3225L22.2187 8.94H23.7917C23.9062 8.94 24 8.841 24 8.72V7.84C24 7.35325 23.6276 6.96 23.1667 6.96ZM10.0417 4.98H17.9583V6.96H10.0417V4.98ZM19.7109 23.02H8.28906L7.65885 8.94H20.3411L19.7109 23.02Z" fill="%23069685"/></svg>');
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    height: 28px;
    width: 28px;
}

.btn-delete-events {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
}

.btn-delete-events-cancel {
    padding: 0;
    font-size: 16px;
    color: #069685;
    background: transparent;
    border: none;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    margin-bottom: 1rem;
}

.settings-dropdown {
    &>.dropdown-container {
        width: 100%;
        margin-bottom: 10px;
    }
}

.settings-block {
    margin: 20px 0 40px 0;
}

.settings-email-input {
    border: none;
    background: none;
}

.settings-activate {
    display: block;
    border: none;
    background: none;
    height: 42px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #328B76;
    cursor: pointer;

    .settings-logout-text {
        display: inline-block;
        vertical-align: top;
        line-height: 28px;
        margin: 0 0 0 8px;
    }
}
.settings-custom-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.row-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row {
    display: flex;
    flex-direction: row;
    
}
.gap-2 {
    gap: 2rem;
}

.w-full {
    width: 100%;
}
.settings-logout {
    border: none;
    background: none;
    height: 42px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #BA403D;
    cursor: pointer;

    .settings-logout-text {
        display: inline-block;
        vertical-align: top;
        line-height: 28px;
        margin: 0 0 0 8px;
    }
}

.settings-container_open {
    height: 100%;
    overflow-y: scroll;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
}

.settings-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 8;
    background: rgba(0, 0, 0, 0.5);
    display: none;

    &_open {
        display: block;
    }
}