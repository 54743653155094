.day-layout-event {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(42, 92, 77, 0.05);
    overflow: hidden;
    // width: calc(50% - 4px);
    // height: calc(50% - 4px);
    width: 50%;
    height: 50%;

    

    

    &.full-width {
        // width: calc(100% - 4px);
        width: 100%;

        .title {
            float: left;
            width: calc(100% - 40px - 200px - 70px);
        }

        .event-icon {
            position: unset;
            float: right;
            margin: 40px 40px 0 0;
        }
    }

    &.full-height {
        // height: calc(100% - 4px);
        height: 100%;
    }

    &.full {
        // width: calc(100% - 4px);
        // height: calc(100% - 4px);
        width: 100%;
        height: 100%;

        .title {
            font-size: 40px;
            line-height: 130%;
            padding-top: 0;
            margin-top: calc(40px + 200px + 14px);
        }

        .event-icon {
            top: 40px
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:nth-of-type(2n) {
        // margin: 4px 0px 4px 4px;
    }

    &:nth-of-type(2n + 1) {
        // margin: 4px 4px 4px 0px;
    }
  
    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin: 0px 0px 20px 0px;
        padding: 40px 40px 0px 40px;
        word-break: break-all;
        overflow: hidden;
        color: #4f5252;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .btn {
        position: absolute;
        width: 36px;
        height: 36px;
        bottom: 10px;
        left: 95%;
        left: calc(100% - 52px);
        padding: 0;
        background: transparent;
    }

    .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        margin: 0;
        padding: 0px 40px 40px 40px;
        display: none;
        position: relative;
        z-index: 1;
    }

    .event-icon {
        position: absolute;
        top: 45px;
        left: calc(50% - 100px);
        z-index: 0;
        width: 200px;
        height: 200px;
    }

    .day-layout-event-short {
        top: 0;
    }

    .day-layout-event-detail {
        top: 100%;
    }

    &:hover {
        .day-layout-event-short {
            border-bottom: 4px solid #7A888C;

            .icon-triangle {
                width: 9px;
                height: 5px;
                left: calc(50% - 4.5px);
            }
        }

        .day-layout-event-detail {
            border-top: 4px solid #7A888C;
            padding-top: 0;

            .icon-triangle {
                width: 9px;
                height: 5px;
                left: calc(50% - 4.5px);
            }
        }
    }

    &_no-description {

        .day-layout-event-short {
            border-bottom: none !important;

            .icon-triangle {
                display: none;
            }

            &:hover {
                border-bottom: none !important;
            }
        }
    }

    &__btn {
        position: sticky;
        top: 0;
        right: 0;
        height: calc(100% - 1px);
        width: calc(100% - 1px);
        float: left;
        margin-right: -100%;
        display: none;
        z-index: 2;

        &_active {
            display: block;
        }
    }

    &_detail {
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scroll-behavior: smooth;

        .day-layout-event-short {
            top: -100%;
        }

        .day-layout-event-detail {
            top: 0;
        }

        .day-layout-event__btn {
            display: block;
        }

        &.full-width .title {
            float: none;
            width: 100%;
        }

        &.full .title {
            margin-top: 40px;
        }

        .desc {
            display: block;
        }

        .event-icon {
            display: none;
        }
    }
}

.day-layout-event_gray {
    color: #4F5252;
}

.border-white {
    border: 1px solid #7A888C;
}

.day-layout-event_white {
    color: #FFFFFF;
}

.day-layout-event-title {
    border-radius: 1000px;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    padding: 0 12px 0 8px;
    display: inline-block;

    i {
        font-size: 20px;
        margin-right: 11px;
    }
}

.day-layout-event-short {
    position: absolute;
    height: 100%;
    width: 100%;
    transition: top 0.3s;
    border-bottom: 2px solid #7A888C;

    .icon-triangle {
        position: absolute;
        bottom: 8px;
        left: calc(50% - 3.5px);
    }
}

.day-layout-event-detail {
    width: 100%;
    position: absolute;
    border-top: 2px solid #7A888C;
    transition: top 0.3s;
    padding-top: 2px;

    .icon-triangle {
        position: absolute;
        transform: rotate(180deg);
        top: 8px;
        left: calc(50% - 3.5px);
    }
}

.day-layout-remove-event {
    display: none;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    float: right;
    color: #069685;
    padding: 0;
    cursor: pointer;
}

.day-layout-checkbox {
    position: absolute !important;
    top: 20px !important;
    right: 20px !important;
    z-index: 4;
}