.new-day-event-form {
    float: left;
    background: #fff;
    width: 616px;
    padding: 20px 20px 20px 20px;
    margin-left: 10px;
    border-radius: 5px;
    box-shadow: 0px 9px 10px rgba(42, 92, 77, 0.05), 0px 2px 3px rgba(42, 92, 77, 0.05), 0px 10px 20px 10px rgba(17, 59, 68, 0.05);

    .new-day-event-form-header {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #535757;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }


    select {
        font-family: 'icomoon', 'Roboto', sans-serif;
        background: #FCFCFC url("../../../icons/icon-arrow-select.svg") no-repeat 90% center;
        -webkit-box-shadow: inset 0 2px 4px rgba(5, 38, 38, 0.05);
        box-shadow: inset 0 2px 4px rgba(5, 38, 38, 0.05);
        border-radius: 5px;
        padding: 9px 8px;
        border: 0;
        margin-bottom: 32px;
        margin-right: 20px;
        color: #828282;
        font-size: 20px;
        width: 72px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    select[name="icon"] {
        color: #828282;
    }

    select[name="color"] {
        color: #069685;
    }

    .dropdown-container.icon-select {
        padding: 0;

        .dropdown-header {
            background: none;
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    .event-icon {
        width: 54px !important;
        height: 42px;
        margin-right: 0 !important;
        z-index: unset !important;

        & .dropdown-header>.icon-smile:before {
            position: absolute;
            display: block;
            width: 24px;
            line-height: 24px;
            top: calc(50% - 12px);
            left: 10px;
            text-align: center;
        }

        .dropdown-header .event-icon-img {
            position: absolute;
            top: calc(50% - 12px);
            left: 8px;
        }

        &-img {
            width: 24px;
            height: 24px;
        }
    }

    .event-form-block {
        display: flex;
        float: left;
    }
}

.event-category {
    float: left;
    width: calc(100% - 66px);
    height: 42px;
    margin: 0px 12px 20px 0px;
    z-index: 2;

    input {
        border: none;
        width: 100%;
        height: 100%;
        padding: 0px 12px;
        background: #FCFCFC;
        box-shadow: inset 0px 2px 4px rgba(5, 38, 38, 0.05);
        border-radius: 5px;
    }

    input::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #9FA3A3;
    }
}

.event-description {
    margin-bottom: 20px;

    textarea {
        resize: none;
        width: 100%;
        max-height: 112px;
        overflow: hidden;
        overflow-y: auto;
        color: #4F5252;
        background: #FCFCFC;
        box-shadow: inset 0px 2px 4px rgba(5, 38, 38, 0.05);
        border-radius: 5px;
        border: 0;
        box-sizing: border-box;
        padding: 11px;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track-piece {
            &:start {
                background: transparent;
                margin-top: 5px;
            }

            &:end {
                background: transparent;
                margin-bottom: 5px;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: #ADB5B3;
        }
    }

    textarea::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #9FA3A3;
    }
}

select {
    &:last-child {
        margin-right: 0;
    }
}

input[type="range"] {
    width: 100%;
}

.event-layout-input {
    clear: both;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #535757;

        width: 100%;
        margin-bottom: 12px;
    }

    .input-hidden {
        position: absolute;
        left: -9999px;
    }

    label {
        float: left;
        margin-right: 12px;
        cursor: pointer;
    }

    input[type=radio]:checked {
        &+label {
            &>svg {
                rect {
                    &:nth-child(2) {
                        fill: #069685;
                    }
                }

                path {
                    fill: #069685;
                }
            }
        }
    }

    svg {
        width: 48px;
        height: 48px;
        cursor: pointer;
    }
}

.btn-save {
    width: 180px;
    height: 42px;
}

.btn-delete {
    padding: 0px 16px 0px 8px;
    height: 42px;
    line-height: 42px;
    float: right;

    .icon-bin {
        &:before {
            position: relative;
            bottom: -7px;
            margin-right: 8px;
        }
    }
}