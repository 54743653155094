.no-events {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        margin: 0;
        font-size: 20px;
        color: #CED6D6;
    }
}
.no-events p:nth-child(3) {
    margin-top: 15px;
}

.no-events-btns {
    display: flex;
    justify-content: center;
    margin-top: 45px;
}