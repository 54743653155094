.restore-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background:url("background-mountainv2.png");
    background-size: cover;
}

.restore-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    background: #FFFFFF;
    box-shadow: 0 9px 10px rgba(42, 92, 77, 0.05), 0 2px 3px rgba(42, 92, 77, 0.05), 0 10px 20px 10px rgba(17, 59, 68, 0.05);
    border-radius: 7px;
}

.restore-form-header {
    background: linear-gradient(144.33deg, #05C8A5 -20.64%, #0C978F 90.26%);
    height: 122px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 0;
    border-radius: 7px 7px 0 0;
}

.restore-form-header h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    margin-top: 64px;
    margin-bottom: 22px;
    margin-left: 43px;
}

.restore-form-input {
    margin-top: 30px;
    margin-bottom: 15px;
}

.restore-form-input input {
    width: 420px;
    height: 42px;
    background: #FCFCFC;
    color: #9FA3A3;
    box-shadow: inset 0 2px 4px rgba(5, 38, 38, 0.05);
    border-radius: 5px;
    border: 0;
    padding: 0 8px;
}

.restore-form-button {
    background: #069685;
    border-radius: 5px;
    border: 0;
    width: 240px;
    height: 42px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-top: 15px;
    margin-bottom: 24px;
    cursor: pointer;
}

.restore-form-link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #069685;
}

.restore-form-footer:last-child {
    margin-bottom: 35px;
}

input::-webkit-input-placeholder {
    font-family: 'icomoon', 'Roboto';
}

input::-moz-placeholder {
    font-family: 'icomoon', 'Roboto';
}

input:-ms-input-placeholder::before {
    font-family: 'icomoon', 'Roboto';
}

input:-moz-placeholder::before {
    font-family: 'icomoon', 'Roboto';
}

.restore-form-input-error {
    line-height: 19px;
    color: red;
    padding: 8px;
}