.thematic {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    opacity: 0.7;
}
.Skeuomorphism .table-view .current-weeks-day::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    z-index: 1;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    background: radial-gradient(77% 96.77% at 50% 50%, #000000 0%, #020202 34.9%, #1f1a1a 60.83%, #666666 74.06%, #000000 90.88%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}
.Skeuomorphism .table-view .first-day-of-the-current-week::after {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    z-index: 1;
    border-top: 4px solid transparent;
    background: radial-gradient(77% 96.77% at 50% 50%, #000000 0%, #020202 34.9%, #1f1a1a 60.83%, #666666 74.06%, #000000 90.88%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}
.Skeuomorphism .table-view .last-day-of-the-current-week::after {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 1;
    border-bottom: 2px solid transparent;
    background: radial-gradient(77% 96.77% at 50% 50%, #000000 0%, #020202 34.9%, #1f1a1a 60.83%, #666666 74.06%, #000000 90.88%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
}


.thematic-highlight {
    background-color: white;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid gray;

    position: absolute;
    z-index: 4;
    top: -10px;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateY(-20px);

    span {
        display: inline-block;
        max-width: 40ch;
        overflow: hidden;
        text-overflow: ellipsis;
        word-spacing: nowrap;
    }
}

.thematic-outside {
    background-color: white;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid gray;
    left: 20px;
    top: 20px;
    position: absolute;
    z-index: 10;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.table-past-day {
    background: #CEE0E1;

}