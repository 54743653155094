.calendar-event .react-resizable-handle::after {
    display: none !important;
}

.no-transitions {
    transition: none !important;
}

.grab {
    cursor: grab;
}