.input {
    background: #FCFCFC;
    box-shadow: inset 0 2px 4px rgba(5, 38, 38, 0.05);
    border-radius: 5px;
    padding: 11px;
    border: 0;
    margin-bottom: 32px;
    margin-right: 20px;
    box-sizing: border-box;
}

.w-360 {
    width: 360px;
}

textarea:focus, input:focus{
    outline: none;
}

.full-width {
    width: 100%;
}