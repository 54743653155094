.layout-switcher {
    float: left;
    margin: 10px 40px 0 0;
    display: flex;
    align-items: center;

    .layout-switcher-btns {
        margin: 0px 40px 0px 0px;

        .layout-switcher-btn {
            cursor: pointer;
            background: none;
            border: none;
            height: 25px;
            margin: 0px 4.5px;
            padding: 0;
        }

        .day-layout-btn {}

        .week-layout-btn {}

        .month-layout-btn {}

        .six-months-layout-btn {}

        .year-layout-btn {}
    }

    label {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: #4F4F4F;
        display: inline-block;
        margin: 0 12px 0 0;
    }

    .range-slider {
        width: 80px;
    }
}

.Skeuomorphism .layout-switcher {
        margin: auto;
        &-btns {
            display: flex;
            margin: 0;
            .green-gradient {
   
                background: linear-gradient(180deg, #84AEA4 50%, #4D897D 50%);
              
            }
            .blue-gradient {
   
                background: linear-gradient(180deg,  #5c9cc9 50%, #4995CC 50%,) ;
              
            }

            .layout-switcher-btn {
                cursor: pointer;
                border: none;
                height: 30px;
                min-width: 30px;
                margin: 0;
                padding: 0 4px 0 4px;
                text-align: center;
                border-right: 1px solid black;
                border-top: 1px solid black;
                border-bottom: 1px solid black;
                box-shadow: inset 0px 1px 1px 0.5px #FDFFFE;
                &:first-child {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-left: 1px solid black;

                }
                &:last-child {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
                &-text {
                    background: linear-gradient(180deg, #797979 50%, #444548 50%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    color: transparent;
                    font-weight: 500;
                }
                &:hover {
                    filter: brightness(1.1);
                }
                &--active {
                    background: linear-gradient(180deg, #E2E4E3 50%, #D9DBDA 50%);
                    &:hover {
                        filter: brightness(1);
                    }
                    cursor: default;
                    color: #FFFFFF;
                }
            }
        }
     
    }