.scrollbar {
    position: absolute;
    height: 100%;
    width: 3px;
    right: 0;
    top: 0;

    .scrollbar-container {
        position: relative;
        height: 100%;
        width: 100%;

        .scrollbar__track {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        .scrollbar__thumb {
            position: absolute;
            height: 50%;
            width: 100%;
            background-color: #069685;
        }
    }

}