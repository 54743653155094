.searchbar {
    float: left;
    width: calc(100% - 66px);
    height: 42px;
    margin: 0px 12px 20px 0px;
    z-index: 2;
    position: relative;

    .searchbar-input {
        border: none;
        width: 100%;
        height: 100%;
        padding: 0px 12px;
        background: #FCFCFC;
        box-shadow: inset 0px 2px 4px rgb(5 38 38 / 5%);
        border-radius: 5px;
    }

    .searchbar-options {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        margin: 0;
        padding: 0 0 0 0;
        background: #FCFCFC;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: inset 0px 2px 4px -4px rgba(5, 38, 38, 0.05);
        max-height: 146px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track-piece {
            &:start {
                background: transparent;
                margin-top: 5px;
            }

            &:end {
                background: transparent;
                margin-bottom: 5px;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: #ADB5B3;
        }

        .searchbar-option {
            text-align: left;
            list-style: none;
            line-height: 36px;
            padding: 0px 12px;

            &:after {
                clear: both;
                content: "";
                display: table;
            }

            .option-btn {
                float: right;
                background: transparent;
                border: none;
                padding: 0;
                cursor: pointer;

                &:before {
                    height: 28px;
                    width: 28px;
                    background-size: cover;
                }
            }

            &:hover {
                background: #828282;
                color: #FCFCFC;
            }
        }
    }
}