.comparison-table {
    border: 0;
    text-align: center;
    margin-bottom: 28px;
    table-layout: fixed;
    background-color: #FAFAFA;

    tr {
        background: #FFFFFF;
        border-radius: 2px;
        color: #737C7C;
    }
}

.comparison-table-cell {
    box-shadow: 0 2px 3px rgba(42, 92, 77, 0.05);
    height: 42px;
    width: 50px;
    min-width: 50px;
}

.has-events {
    background-color: #5DA299;
}

.comparison-table-header-row {
    color: #5DA299;
    background-color: #5DA299;

    th {
        &:nth-child(even) {
            background-color: #E4F2EF;
        }
    }
}

.comparison-header-years {
    margin-top: 34px;

    .dropdown-container {
        width: 100px;
    }
}

.comparison-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #E4F2EF;
    background-color: #FFFFFF;
    color: #737C7C;
    max-width: 400px;
}