.checkbox::before{
    border-radius: 1em !important;
    border: 1px solid #FFFFFF !important;
    box-shadow: 0px 2px 5px 0px #00000066 inset ;
    box-shadow: 0px 1px 1px 0px #00000040;

}
.checkbox{
    border-radius: 1em !important;
    border:1px solid #FFFFFF !important;
    background: #FFFFFF !important;
    border-color: #FDFDFD !important;
    box-shadow: 0px 2px 5px 0px #00000066 inset;
    position: absolute ;
    right: 5% !important;
    height: 16px !important;
    top:5% !important;
    width: 16px !important;
    
}


