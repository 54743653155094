
.thematics-layout {
	padding: 120px;
}
.thematics-layout-header {
	font-family: 'Roboto' sans-serif;
	font-size: 32px;
	font-weight: 300;
	line-height: 38px;
	letter-spacing: 0px;
	text-align: left;
	color: #000000;
}

.thematics-layout-body{
	margin-top: 44px;
	display: flex;
	flex-direction: row;
	gap: 40px;
	overflow: auto;
	width: 100%;
}
.thematics-container{
	min-width: 400px;
}
.thematics {
	display: flex;
	flex-direction: column;
	gap:10px;
}
.add-thematic{
	border: 0;
	color:#069685;
	background-color: transparent;
	font-family: 'Roboto' sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0px;
	text-align: left;
	cursor: pointer;
	margin-bottom: 40px;
}

.thematic-button:last-child{
	margin-bottom: 40px;
}