.thematics-form {
    margin: auto;
    position: relative;
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 9px 10px rgba(42, 92, 77, 0.05), 0px 2px 3px rgba(42, 92, 77, 0.05), 0px 10px 20px 10px rgba(17, 59, 68, 0.05);

    .thematics-form-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .thematics-form-block {
        display: flex;
        margin-bottom: 20px;
    }
}
.existing-thematics-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
    align-items: center;
    max-height: 300px;
    overflow: auto;
}

.existing-thematic{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;
    justify-items: center;
  
  
}

.thematics-edit-button-container{
    display: flex;
    flex-direction:  row;
    gap: 12px; 
    justify-content:space-between
}

.thematics-replace-button-container {
    display: flex;
    flex-direction:  row;
    gap: 12px; 
    
}
.thematic-input {
    box-shadow: 2px 4px 4px 0px #0526260D inset;
    padding: 0px, 12px, 0px, 12px;
    border-radius: 5px;
    border:0;
}