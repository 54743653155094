.dropdown-container {
    display: inline-block;
    font-family: 'icomoon', 'Roboto', sans-serif;
    border: 0;
    font-size: 20px;
    width: 42px;
    position: relative;
    cursor: pointer;
    z-index: 3;

    &.icon-select {
        display: inline-block;
        font-family: 'icomoon', 'Roboto', sans-serif;
        -webkit-box-shadow: inset 0 2px 4px rgba(5, 38, 38, 0.05);
        box-shadow: inset 0 2px 4px rgba(5, 38, 38, 0.05);
        border-radius: 5px;
        padding: 9px 8px;
        border: 0;
        margin-right: 20px;
        color: #828282;
        font-size: 20px;
        width: 72px;
        position: relative;
        background: #FCFCFC;
        cursor: pointer;
        z-index: 3;

        .dropdown-header {
            background: url("../../../icons/icon-arrow-select.svg") no-repeat 90% center;
        }
    }

    &.year-select {
        .dropdown-header {
            &:hover {
                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    right: -10px;
                    bottom: 0;
                    background: url("../../../icons/icon-arrow-select.svg") no-repeat 90% center;
                }
            }
        }
    }

    &:last-child {
        margin-right: 0;
    }
}
.Skeuomorphism .max-w-40px {
    max-width: 40px;
    width:40px
}
.dropdown-container.view-select,
.dropdown-container.month-select {
    -webkit-box-shadow: inset 0 2px 4px rgba(5, 38, 38, 0.05);
    box-shadow: inset 0 2px 4px rgba(5, 38, 38, 0.05);
    border-radius: 5px;
    padding: 9px 8px;
}

.dropdown-container.view-select .dropdown-header,
.dropdown-container.month-select .dropdown-header {
    background: url("../../../icons/icon-arrow-select.svg") no-repeat 90% center;
}
.font-fill-select {
    display: flex;
    align-items: center;
    justify-content: center;
     box-shadow: 0px 2px 2px 0px #19221F12;
      border-radius: 5px
}
.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    margin: 0;
    padding: 0 0 0 0;
    background: #FCFCFC;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.dropdown-list-item {
    text-align: center;
    list-style: none;
    padding-bottom: 0.3em;

    &:hover {
        background: #828282;
        color: #FCFCFC;
    }
}