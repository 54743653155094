.week {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
    height: 80vh;
}
@scope(.Skeuomorphism) {
    .period-is-week{
        .calendar-day-container__number {
            font-size: 16px;
        }
    }
    
    .weekday {
        border: .42px solid #000 !important;
        box-shadow: inset 0 1px 1px 0.5px #fdfffe !important;
        border-radius: 3px;
        cursor: pointer;
        height: 29px;
        &--span{
            background: linear-gradient(0deg, #2C2B29 50%, #656563 50%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
            font-family: Inter;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.36px;
            letter-spacing: 0.01em;
            text-align: center;
        }
        &:hover {
            filter:brightness(1.1);
        }
    }
    .current-weekday {
        background: linear-gradient(180deg, #616262 50%, #141515 50.01%) !important;;
        border: 1px solid #000 !important;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px 0.5px #fdfffe !important;
        cursor: pointer;
        .weekday--span {
            color:white;
            -webkit-text-fill-color:unset

        }
    }
    .low-opacity {
        opacity: 0.47;
    }
   
}