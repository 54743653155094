.day {
    min-width: 296px;
    max-width: 296px;
    display: flex;
    flex-direction: column;
    margin: 0 10px;

    &:hover {
        .day-add-button {
            opacity: 1;
        }
    }
}

.day-header-weekday {
    font-weight: bold;
}

.day-header-date {
    font-weight: 300;
}

.day-header {
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #3A4242;
    padding: 16px;
}

.day-content {
    background: #FFFFFF;
    box-shadow: 0 2px 3px rgba(42, 92, 77, 0.05);
    border-radius: 5px;
    padding: 24px 20px 20px;
}

.day-header--today {
    color: #5DA199;
}

.day-content-event-header {
    border-radius: 1000px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #FFFFFF;
    padding: 0 12px 0 8px;
    display: inline-block;

    i {
        font-size: 20px;
        margin-right: 11px;
    }
}

.day-content-event-rating {
    margin: 12px 0;
}

.day-content-event {
    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 0;
    }
}

.day-content-event-description {
    overflow-wrap: break-word;
}

.empty {
    color: #212424;
    text-align: center;
}

.day-add-button {
    margin-top: 20px;
    display: inline-block;
    font-size: 16px;
    line-height: 28px;
    color: #069685;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}