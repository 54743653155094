.filter {
    .filter-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #252525;
        border: none;
        background: #FAFAFA;
        border-radius: 39px;
        padding: 4px 12px;
        margin: 6px 4px;
        cursor: pointer;

        &_selected {
            background: #328B76;
            color: #FFFFFF;
        }
    }
}