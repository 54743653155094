.range-slider {
    -webkit-appearance: none;
    height: 1px;
    background: #C8CACA;
    box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    outline: none;
    cursor: grab;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        // box-shadow: 0px 2px 3px rgba(42, 92, 77, 0.05);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.17), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        cursor: grab;
    }

    &::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid black;
        background: #FFFFFF;
        // box-shadow: 0px 2px 3px rgba(42, 92, 77, 0.05);
        // background: #BDBDBD;
        // border: 2px solid #FFFFFF;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.17), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        cursor: grab;
    }
}