/* The switch - the box around the slider */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 24px;
    vertical-align: top;

    &-label {
        display: inline-block;
        line-height: 24px;
        margin: 0px 0px 0px 14px;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: #309280;
    }

    input:focus+.slider {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(28px);
        -ms-transform: translateX(28px);
        transform: translateX(28px);
    }

    .slider {
        border-radius: 12px;
    }

    .slider:before {
        border-radius: 50%;
    }
}