.emoji-select {
    position: relative;

    .emoji-header {
        float: right;

        text-align: center;
        font-size: 20px;
        line-height: 42px;

        border: none;
        width: 54px;
        height: 42px;
        padding: 0px 12px;
        background: #FCFCFC;
        box-shadow: inset 0px 2px 4px rgba(5, 38, 38, 0.05);
        border-radius: 5px;
    }

    .emoji-select-container {
        position: absolute;
        top: 42px;
        right: 0;
        z-index: 99;
    }
}

.emoji-picker {
    width: 350px;
    height: 450px;

    background: #fff;
    border-color: #e7e7e7;
    border-radius: 8px;
    overflow: hidden;

    .emoji-groups {
        display: flex;
        width: 100%;

        .emoji-groups-btn {}
    }

    .emoji-group {
        width: 100%;
        height: calc(100% - 40px - 50px);
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;

        .emoji-group-header {
            color: #858585;
        }

    }

    .emoji-footer {
        height: 50px;
        font-size: 40px
    }

    .emoji-btn {
        width: 30px;
        height: 30px;
        padding: 0 12px;
        border: none;
        background: #FCFCFC;
        margin-bottom: 10px;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        color: #050505;
        cursor: pointer;

        &:hover {
            background: #F2F2F2;
        }
    }
}