.modal {
    position: fixed;
    top: -500000px;
    left: -500000px;
    right: -500000px;
    bottom: -500000px;
    background: rgba(0, 0, 0, 0.15);
    z-index: 4;
    display: flex;
    // overflow: scroll;
}

.right {
    justify-content: end;
}

.center {
    justify-content: center;
}