.btn {
    cursor: pointer;
    padding: 11px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #069685;
    background: #FFFFFF;
    border-radius: 5px;
    border: 0;
}

/* .btn:hover {
    color: #37B28D;
} */

.btn:active {
    color: #069685;
}

.btn-green {
    background: linear-gradient(180deg, #069685 0%, #069685 100%);
    color: #FFFFFF;
}

.btn-green:hover {
    color: #FFFFFF;
    background: #37B28D;
}

.btn-green:active {
    background: linear-gradient(0deg, rgba(6, 150, 133, 0.4), rgba(6, 150, 133, 0.4)), #37B28D;
    color: #F9F9F9;
}


.btn-red {
    background: #BA403D;
    color: #FFFFFF;
}

.btn-red:hover{
    filter:brightness(1.2);
}