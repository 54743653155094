.switcher {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    z-index: 5;
    align-items: center;
}

.switcher__item {
    z-index: 5;
    display: inline-block;
    vertical-align: middle;

    &:last-child {
        position: absolute;
        z-index: 4;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
    }
}

.Skeuomorphism .switcher {
    margin-left: 44px;
}

@scope(.Skeuomorphism) {
    .switcher-period-is-week {
        margin-left: 0px !important;
    }
}

.Skeuomorphism .switcher__item {
    &:last-child {
       display: none;
    }
}
.Skeuomorphism .switcher__item--previous {
    margin-right: 8px;
    color: #7A888C;
}
.Skeuomorphism .switcher__item--next {
    margin-left: 8px;
    color: #7A888C;
}
.Skeuomorphism .calenday-day-container__extra {
    width: 14px;
    height: 14px;
    background: linear-gradient(180deg, #DDDFDE 49.99%, #C5C7C6 50%);
    border: 0.5px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 4px;
    bottom: 4px;

    
}

.switcher__item-text {
    vertical-align: middle;
    font-size: 20px;
}

.switcher__item--previous {
    margin-right: 20px;
    color: #7A888C;
  
}

.Skeuomorphism .gradient-text {
    background: linear-gradient(180deg, #797979 50%, #444548 50%);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-weight: 500;
}
.Skeuomorphism .switcher__item--previous {
    display: flex;
    align-items: center;
    &:hover {
        filter:brightness(1.1)
    }
}
.switcher__item--next {
    margin-left: 20px;

    color: #7A888C;
}
.Skeuomorphism .switcher__item--next {
    display: flex;
    align-items: center;
    &:hover {
        filter:brightness(1.1)
    }
}
.switcher__item--today {
    font-size: 16px;
    color: #7A888C;
    cursor: pointer;
}
.Skeuomorphism .switcher__item--today {
    border: 1px solid;
    color:  black;


    padding: 7px 20.5px 7px 20.5px;
    gap: 10px;
    border-radius: 5px;
    background: linear-gradient(180deg, #84AEA4 50%, #4D897D 50%);

    border-image-source: linear-gradient(310.09deg, rgba(255, 255, 255, 0) 67.38%, #FFFFFF 105.43%),
    linear-gradient(54.12deg, rgba(255, 255, 255, 0) 79.14%, #FFFFFF 101.37%);
    box-shadow: inset 0px 1px 1px 0.5px #FDFFFE;

    &:hover {
        filter:brightness(1.1)
    }
}
.Skeuomorphism .blue-gradient {
   
    background: linear-gradient(180deg,  #5c9cc9 50%, #4995CC 50%,) ;
  
}
.Skeuomorphism .green-gradient {
   
    background: linear-gradient(180deg, #84AEA4 50%, #4D897D 50%);
  
}


.Skeuomorphism .month span {
    background: linear-gradient(180deg, #797979 50%, #444548 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-weight: 700;
}
.switcher__month-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;

}

.switcher__year-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
}