.react-resizable:hover{
    .react-resizable-handle { 
        display: block; 
      } 
}
.react-resizable:active{
    .react-resizable-handle { 
        display: block; 
      } 
}
.react-resizable-handle { 
    display: none; 
} 
.react-grid-item.react-grid-placeholder {
    background-color: #0b797280
  }