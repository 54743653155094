.day-layout {
    margin: auto;
    border-radius: 5px;
    position: relative;
}

.day-layout::after {
    content: " ";
    clear: both;
    display: table;
}

.day-layout-date {
    display: block;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #535757;
}

.day-layout-weekday {
    display: block;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #9FA3A3;
}

.day-layout-content {
    padding: 20px;
    width: 932px;
    max-height: 768px;
    height: 87vh;
    background: #FFFFFF;
    box-shadow: 0px 9px 10px rgba(42, 92, 77, 0.05), 0px 2px 3px rgba(42, 92, 77, 0.05), 0px 10px 20px 10px rgba(17, 59, 68, 0.05);
    border-radius: 5px;
    resize: both;
    float: left;
    position: relative;
    display: flex;
    flex-direction: column;

}
.day-layout-thematic-exist {
    cursor: pointer;
    position: absolute;
    top: 20px;
    justify-items: center;
    right: 1em;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 12px;
    border: 1px solid gray;
    display: flex;
    gap: 40px;
    border-radius: 3px;
}
.day-layout-thematic-empty {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 1em;
    color: #069685;
    background-color: transparent;
    border: 0;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 12px;
    font-weight: 500;
}
.day-layout-btns {
    margin-top: 20px;
    .hidden-events-btn {
        float: right;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #9FA3A3;
        background: none;
        border: none;
        display: flex;
        align-items: center;
    }
}

.day-layout-events {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width:1560px) {
    .day-layout-content {
        width: 700px;
    }
}
@media (max-height:700px) {
    .text-editor{
         margin: 10px 0px 15px 0px;
    }
 
 }
 @media (max-width:1300px) {
    .day-layout-content {
        width: 600px;
    }
}