
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    background: #FAFAFA;
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.increase-0 {
    font-size: 1em;
}
.increase-1 {
    font-size: 1.1em;
}

.increase-2 {
    font-size: 1.2em;
}

.increase-3 {
    font-size: 1.3em;
}

.increase-4 {
    font-size: 1.4em;
}

.increase-5 {
    font-size: 1.5em;
}

.increase-6 {
    font-size: 1.6em;
}

.increase-7 {
    font-size: 1.7em;
}

.increase-8 {
    font-size: 1.8em;
}

.increase-9 {
    font-size: 1.9em;
}

.increase-10 {
    font-size: 2em;
}