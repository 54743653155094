.calendar {
    background: #fafafa;

    .panel {
        z-index: 5;
        background: #fafafa;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        position: relative;

        .settings-switcher-container {
            z-index: 5;
            display: flex;
        }
    }
}

.Skeuomorphism {
    font-family: 'Inter', sans-serif !important;
    .settings-switcher-container {
        gap: 40px;
        margin-right: 16px;
    }
    .panel {
        margin-top: 6px;
        margin-bottom: 6px;
    }
}

@scope(.Skeuomorphism) {
    .panel-period-is-week {
        background: #EBEBEB !important;
        padding-left: 4px;
        padding-right: 14px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin:4px;
        border-radius:1px;
    }
}
.Skeuomorphism 
.delete-events-settings{
    display: flex;
    flex-direction: row;
    align-items: center;
    float: left;
    margin-top: 8px;
    margin-right: 40px;
}

.icon-bin-gray::before{
    background-image: url('data:image/svg+xml,<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.1667 6.96H19.8333V4.76C19.8333 3.78925 19.0859 3 18.1667 3H9.83333C8.91406 3 8.16667 3.78925 8.16667 4.76V6.96H4.83333C4.3724 6.96 4 7.35325 4 7.84V8.72C4 8.841 4.09375 8.94 4.20833 8.94H5.78125L6.42448 23.3225C6.46615 24.2603 7.20052 25 8.08854 25H19.9115C20.8021 25 21.5339 24.263 21.5755 23.3225L22.2187 8.94H23.7917C23.9062 8.94 24 8.841 24 8.72V7.84C24 7.35325 23.6276 6.96 23.1667 6.96ZM10.0417 4.98H17.9583V6.96H10.0417V4.98ZM19.7109 23.02H8.28906L7.65885 8.94H20.3411L19.7109 23.02Z" fill="%235D6A6E"/></svg>');
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    height: 28px;
    width: 28px;
}

.btn-event-delete-cancel{
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    color: #5D6A6E;
    cursor: pointer;
}
.btn-event-delete{
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    color: #BA403D;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    
}

.btn-event-delete-confirm{
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #5D6A6E;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;

}

.settings-icon {
    display: inline-block;
    margin: 8px 8px 0 0;
    cursor: pointer;

    i {
        font-size: 32px;
        color: #DADADA;
    }
}

.Skeuomorphism .settings-icon {
    margin: auto;
    &:hover {
      filter:invert(1)
    }
}

.blue {
    i {
        &.icon-gear-2 {
            color: #4995CC;
        }
    }
}

.day-container {
    display: flex;
    justify-content: center;
}